<template>
  <div id="game-3"> 
    <header>
      <button @click="isSelectedMission = false" v-if="isSelectedMission">MENU</button>
      <div v-else>
        <router-link to="/games"><button>Wróć</button></router-link>
        Witaj {{ user.displayName }}
      </div>
      <button @click="showHelpScreen = !showHelpScreen">?</button>
    </header>

    <div class="menu-screen content" v-if="!isSelectedMission && !prologActive">
      <h1>Menu</h1>
      <p>Każda z misji odkoduję losową literę z hasła głównego.</p>
      <div class="menu-missions">
        <div v-for="(mission, index) in missions" :key="index">
          <button @click="selectMission(index)" :disabled="missions[index].isComplete">{{ mission.name }}</button>
        </div>
      </div>
    </div>

    <div class="game-screen content" v-if="isSelectedMission && !prologActive">
      <h1>{{ missions[selectedMission].name }}</h1>

      {{ missions[selectedMission].question }}
      
      <div class="answer-buttons">
        <img :src="answer" v-for="(answer, index) in missions[selectedMission].answersImages" :key="index" @click="selectAnswer(index)">
      </div>

      <button v-if="missions[selectedMission].isComplete" @click="winMission()">Wróć do menu</button>
    </div>

    <div class="password-screen content" v-if="prologActive">
      <!-- TODO: Prolog, odkodowanie hasła z tablicy znaków -->
      <h1>Prolog</h1>
      Odkoduj kryptonim:<br>
      3:5 1:1 2:3 2:4 3:6 _ 2:5 4:6 3:6 2:2 1:5<br><br>
      <p :class="{'correct-password': prologComplete}">{{ prologUserPassword }}</p>
      <div class="alphabet-grid">
        <button v-for="(letter, index) in dictionary" :key="index" @click="prologSelectLetter(letter)">{{ letter }}</button>
      </div>
      <button @click="prologUserPassword = ''" :disabled="prologComplete">Resetuj</button>
      <button @click="prologActive = false" :disabled="!prologComplete">Zamknij</button>
    </div>

    <footer>
      <div v-if="!prologActive">
        ODKODOWANE: {{ password }}<br>
        ZAKODOWANE: {{ staticPassword }}
      </div>
    </footer>

    <help-screen v-if="showHelpScreen" :pages="helpPages"></help-screen>
  </div>
</template>

<script>
/*
Zagadka 3
Całość zaczyna się od prologu (.password-screen) gdzie rozwiązujemy prostą zagadkę opartą o tablicę znaków na której zgodnie z wyświetlanym szyfrem składającym się sekwencji wiersz-kolumna klikamy kolejno na litery z tablicy które utworzą hasło wstępne

Po utworzeniu hasła wyświetla się .menu-screen gdzie na mapie są zaznaczone miejsca wraz z oznaczeniem literowym (jest to fałszywa litera którą należy odkodować aby poznać hasło główne). Każde z miejsc na mapie to jedna litera do odkodowania. Wybranie miejsca odpala .game-screen

W .game-screen odbywa się główna mechanika identyczna z mechaniką z Game_1 czyli odpowiedzi A B C D ale w formie obrazkowej. Całość polega na tym, że po wybraniu się w dane miejsce musimy z pośród opcji A B C D wybrać to zdjęcie na którym znajduje się obiekt z wybranej lokalizacji. Do odpowiedzi obrazkowych przyporządkowane są litery. Wybranie prawidłowej odpowiedzi odkodowuje fałszywą literę na tę wybraną z pośród zdjęć A B C D.

W widokach .menu-screen oraz .game-screen tablica odkodowująca musi być cały czas widoczna.
*/
import HelpScreen from '@/components/HelpScreen';
import firebase from '@/firebase';

export default {
  components: {
    HelpScreen
  },
  data() {
    return {
      showHelpScreen: false,
      helpPages: [
        {
          title: "Tip 1",
          content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita, totam sit commodi ducimus, temporibus enim a blanditiis aliquid porro quae quidem non incidunt quam provident distinctio impedit quas. Assumenda, voluptate."
        },
        {
          title: "Tip 2",
          content: "Ipsum dolor sit amet consectetur adipisicing elit. Expedita, totam sit commodi ducimus, temporibus enim a blanditiis aliquid porro quae quidem non incidunt quam provident distinctio impedit quas. Assumenda, voluptate."
        },
        {
          title: "Tip 3",
          content: "Dolor sit amet consectetur adipisicing elit. Expedita, totam sit commodi ducimus, temporibus enim a blanditiis aliquid porro quae quidem non incidunt quam provident distinctio impedit quas. Assumenda, voluptate."
        },
      ],
      dictionary: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'R', 'S', 'T', 'U', 'V', 'W', 'Y', 'Z', '_'],
      prologActive: true,
      prologComplete: false,
      prologPassword: "tajny_szyfr",
      prologUserPassword: "",
      isSelectedMission: false,
      selectedMission: 0,
      password: "BCBCBD",
      staticPassword: "BCBCBD",
      usedChars: [],
      selectedLetter: "",
      decoderArray: [
        {
          code: "B",
          val: "A"
        },
        {
          code: "C",
          val: "N"
        },
        {
          code: "D",
          val: "S"
        }
      ],
      missions: [
        {
          name: "Misja 1",
          description: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Commodi, sequi recusandae. Voluptatum aspernatur minima magni odit nisi nobis alias, ipsum debitis rem perferendis atque repudiandae. Dolor iste voluptatum omnis qui.",
          isComplete: false,
          question: "Treść pytania",
          answersImages: [
            "https://miro.medium.com/max/2400/1*MI686k5sDQrISBM6L8pf5A.jpeg", 
            "https://miro.medium.com/max/2400/1*MI686k5sDQrISBM6L8pf5A.jpeg", 
            "https://miro.medium.com/max/2400/1*MI686k5sDQrISBM6L8pf5A.jpeg", 
            "https://miro.medium.com/max/2400/1*MI686k5sDQrISBM6L8pf5A.jpeg"
          ],
          correctAnswer: 0,
        },
        {
          name: "Misja 2",
          description: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Commodi, sequi recusandae. Voluptatum aspernatur minima magni odit nisi nobis alias, ipsum debitis rem perferendis atque repudiandae. Dolor iste voluptatum omnis qui.",
          isComplete: false,
          question: "Treść pytania",
          answersImages: [
            "https://miro.medium.com/max/2400/1*MI686k5sDQrISBM6L8pf5A.jpeg", 
            "https://miro.medium.com/max/2400/1*MI686k5sDQrISBM6L8pf5A.jpeg", 
            "https://miro.medium.com/max/2400/1*MI686k5sDQrISBM6L8pf5A.jpeg", 
            "https://miro.medium.com/max/2400/1*MI686k5sDQrISBM6L8pf5A.jpeg"
          ],
          correctAnswer: 0,
        },
        {
          name: "Misja 3",
          description: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Commodi, sequi recusandae. Voluptatum aspernatur minima magni odit nisi nobis alias, ipsum debitis rem perferendis atque repudiandae. Dolor iste voluptatum omnis qui.",
          isComplete: false,
          question: "Treść pytania",
          answersImages: [
            "https://miro.medium.com/max/2400/1*MI686k5sDQrISBM6L8pf5A.jpeg", 
            "https://miro.medium.com/max/2400/1*MI686k5sDQrISBM6L8pf5A.jpeg", 
            "https://miro.medium.com/max/2400/1*MI686k5sDQrISBM6L8pf5A.jpeg", 
            "https://miro.medium.com/max/2400/1*MI686k5sDQrISBM6L8pf5A.jpeg"
          ],
          correctAnswer: 0,
        }
      ]
    }
  },
  mounted() {
    firebase.auth().onAuthStateChanged(user =>  {
      if (user) {
        this.$store.commit('SET_USER', user);
        this.getUserData();
      } else {
        this.$router.push('/');
      }
    });
  },
  methods: {
    async getUserData() {
      const db = firebase.firestore();
      const ref = db.collection('users').doc(this.$store.state.currentUser.uid);
      const doc = await ref.get();
      if (!doc.exists) {
        console.log('No such document!');
        this.$router.push("/games");
      } else {
        if (doc.data().games.game3.active == false) {
          this.$router.push('/games');
        }
        if (doc.data().games.game3.missions) {
          console.log("Restore from save");
          this.prologActive = doc.data().games.game3.prologActive;
          this.prologComplete = doc.data().games.game3.prologComplete;
          this.password = doc.data().games.game3.password;
          this.usedChars = doc.data().games.game3.usedChars;
          this.missions = doc.data().games.game3.missions;
        } else {
          console.log("Save not exist!")
        }
      }
    },
    async updateUserData() {
      const gameData = {
        name: 'Gra 3',
        url: '/game-3',
        active: true,
        prologActive: this.prologActive,
        prologComplete: this.prologComplete,
        password: this.password,
        usedChars: this.usedChars,
        missions: this.missions
      };

      const db = firebase.firestore();
      await db.collection('users').doc(this.$store.state.currentUser.uid).update({
        'games.game3': gameData
      });
    },
    selectMission(index) {
      this.isSelectedMission = true;
      this.selectedMission = index;
    },
    decodeLetter(letter) {
      let decodeObj = this.decoderArray.find(c => c.code === letter);
      this.password = this.password.replaceAll(letter, decodeObj.val);
      this.usedChars.push(letter); // Dodaj dekodowaną literę do stosu już użytych
    },
    rollLetter() {
      if (this.usedChars.length != this.decoderArray.length) {
        let randomIndex = Math.floor(Math.random() * (this.decoderArray.length));
        let randomLetter = this.decoderArray[randomIndex].code;
        
        if (this.usedChars.includes(randomLetter)) {
          // Losuj dopóki nie będzie powtórzenia
          while (this.usedChars.includes(randomLetter)) {
            randomIndex = Math.floor(Math.random() * (this.decoderArray.length));
            randomLetter = this.decoderArray[randomIndex].code;
          }
        }

        console.log("Roll letter", randomLetter);
        this.decodeLetter(randomLetter);
      } else {
        console.log("Hasło odkodowane!")
      }
    },
    selectAnswer(index) {
      if (this.selectedMission >= 0) {
        const isCorrect = (index === this.missions[this.selectedMission].correctAnswer) ? true : false;
        if (isCorrect && !this.missions[this.selectedMission].isComplete) {
          this.rollLetter();
          this.missions[this.selectedMission].isComplete = true;
          this.updateUserData();
        }
      }
    },
    winMission() {
      this.isSelectedMission = false; // powrót do listy misji
    },
    prologSelectLetter(letter) {
      if (!this.prologComplete) this.prologUserPassword += letter;
      if (this.prologPassword.toUpperCase() == this.prologUserPassword.toUpperCase()) { 
        this.prologComplete = true;
        this.prologActive = false;
        this.updateUserData();
      }
    }
  },
  computed: {
    user() {
      if (this.$store.state.currentUser)
        return this.$store.state.currentUser;
      else
        return { displayName: "" }
    }
  }
}
</script>

<style scoped>
header {
  width: 100%;
  height: 50px;
  background: red;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  color: white;
}
footer {
  width: 100%;
  height: 50px;
  background: red;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: white;
  padding: 0 1rem;
}
footer .slot {
  width: 40px;
  height: 40px;
  background: #fff;
  border: 1px solid;
  margin: 0 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.menu-screen {
  margin-top: 50px;
  min-height: calc(100vh - 50px);
}
.game-screen {
  margin: 50px 0;
  min-height: calc(100vh - 100px);
}
.content {
  margin: 50px 0;
  padding: 1rem;
}
.progress-bar {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background: #f5f5f5;
}
.progress-bar div {
  width: 30px;
  height: 30px;
  border: 1px solid;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.active-item {
  font-weight: bold;
  border: 3px solid !important;
}
.answer-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 1rem;
}
.answer-buttons img {
  margin: 1rem 0;
  width: 100%;
}
.password-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.alphabet-grid {
  display: grid;
  grid-template-columns: repeat(4, 50px);
  grid-template-rows: repeat(7, 50px);
  gap: 1rem;
  margin: 1rem 0;
}
.alphabet-grid button:last-child {
  grid-column: 1/5;
}
.correct-password {
  color: green;
}
</style>